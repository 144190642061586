import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'burger_open',
    'burger_close',
    'menu'
  ]

  connect() {
  }

  toggleBurger(event) {
    this.burger_openTarget.classList.toggle('hidden')
    this.burger_closeTarget.classList.toggle('hidden')
    this.menuTarget.classList.toggle('hidden')
  }
}
