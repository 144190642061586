import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'survey_modal',
    'survey_step',
    'step_container',
    'help_modal',
    'error',
    'help_approbation_modal',
    'help_amour_modal',
    'help_realisation_modal',
    'help_perfectionnisme_modal',
    'help_conscience_modal',
    'help_omnipotence_modal',
    'help_autonomie_modal',
  ]

  connect() {
    this.step = 1
    this.survey_stepTarget.innerText = this.step
    this.das_survey = {
      e1: null, e2: null, e3: null, e4: null, e5: null, e6: null, e7: null, e8: null, e9: null, e10: null, e11: null, e12: null, e13: null, e14: null, e15: null, e16: null, e17: null, e18: null, e19: null, e20: null, e21: null, e22: null, e23: null, e24: null, e25: null, e26: null, e27: null, e28: null, e29: null, e30: null, e31: null, e32: null, e33: null, e34: null, e35: null,
    }
  }

  new(e) {
    e.preventDefault()
    document.body.classList.add('overflow-hidden')
    this.survey_modalTarget.classList.remove('hidden')
  }

  close_survey_modal(e) {
    e.preventDefault()
    document.body.classList.remove('overflow-hidden')
    this.survey_modalTarget.classList.add('hidden')
  }

  next_step(e) {
    e.preventDefault()
    if(this.das_survey[`e${this.step}`] == null) {
      this.errorTarget.classList.remove('hidden')
      return
    }
    this.errorTarget.classList.add('hidden')
    this.step += 1
    this.survey_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  previous_step(e) {
    e.preventDefault()
    this.step -= 1
    this.survey_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  choose_answer(event) {
    const value = parseInt(event.target.value)
    this.das_survey[event.target.name] = value
    this.errorTarget.classList.add('hidden')
  }

  submit(e) {
    e.preventDefault()
    console.log(this.das_survey)
    axios.post('/das_surveys', {
      das_survey: this.das_survey
    }).then(response => {
      if(response.data.success) {
        window.location.reload()
      }
    })
  }

  open_help_modal(e) {
    e.preventDefault()
    document.body.classList.add('overflow-hidden')
    console.log(e.target.dataset.type)
    if(e.target.dataset.type == 'approbation')
      this.help_approbation_modalTarget.classList.remove('hidden')
    
    if(e.target.dataset.type == 'amour')
      this.help_amour_modalTarget.classList.remove('hidden')

    if(e.target.dataset.type == 'realisations')
      this.help_realisation_modalTarget.classList.remove('hidden')

    if(e.target.dataset.type == 'perfectionnisme')
      this.help_perfectionnisme_modalTarget.classList.remove('hidden')

    if(e.target.dataset.type == 'conscience')
      this.help_conscience_modalTarget.classList.remove('hidden')

    if(e.target.dataset.type == 'omnipotence')
      this.help_omnipotence_modalTarget.classList.remove('hidden')

    if(e.target.dataset.type == 'autonomie')
      this.help_autonomie_modalTarget.classList.remove('hidden')
  }

  close_help_modal(e) {
    e.preventDefault()
    document.body.classList.remove('overflow-hidden')
    this.help_modalTarget.classList.add('hidden')
  }

  close_help_approbation_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_approbation_modalTarget.classList.add('hidden')
  }
  close_help_amour_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_amour_modalTarget.classList.add('hidden')
  }
  
  close_help_realisation_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_realisation_modalTarget.classList.add('hidden')
  }

  close_help_perfectionnisme_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_perfectionnisme_modalTarget.classList.add('hidden')
  }

  close_help_conscience_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_conscience_modalTarget.classList.add('hidden')
  }

  close_help_omnipotence_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_omnipotence_modalTarget.classList.add('hidden')
  }

  close_help_autonomie_modal() {
    document.body.classList.remove('overflow-hidden')
    this.help_autonomie_modalTarget.classList.add('hidden')
  }
}
