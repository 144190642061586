import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'survey_modal',
    'survey_step',
    'error',
    'step_container'
  ]

  connect() {
    console.log('connected')
    this.step = 1
    this.survey_stepTarget.innerText = this.step
    this.swash_survey = {
      e1: null, e2: null, e3: null, e4: null, e5: null, e6: null, e7: null, e8: null, e9: null, e10: null, e11: null, e12: null
    }
  }

  next_step(e) {
    e.preventDefault()
    if(this.swash_survey[`e${this.step}`] == null) {
      this.errorTarget.classList.remove('hidden')
      return
    }
    this.errorTarget.classList.add('hidden')
    this.step += 1
    this.survey_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  previous_step(e) {
    e.preventDefault()
    this.step -= 1
    this.survey_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  submit(e) {
    console.log(this.swash_survey)
    e.preventDefault()
    axios.post('/swash_surveys', {
      swash_survey: this.swash_survey
    }).then(response => {
      if(response.data.success) {
        window.location.reload()
      }
    })
  }

  choose_answer(event) {
    const value = parseInt(event.target.value)
    this.swash_survey[event.target.name] = value
    this.errorTarget.classList.add('hidden')
  }

  open_modal() {
    this.survey_modalTarget.classList.remove('hidden')
  }

  close_modal() {
    this.survey_modalTarget.classList.add('hidden')
  }
}
