import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'login_button',
    'email_input',
    'password_input',
    'login_error',
    'unsubscribe_modal',
    'email_input_unsubsribe',
    'login_modal',
    'unsub_ok',
    'unsub_error',
    'login_form',
    'forgot_password_form',
    'modal_title',
    'reset_success',
    'forgot_password_link'
  ]

  connect() {
  }

  open_modal() {
    this.login_modalTarget.classList.remove('hidden')
  }

  close_modal() {
    this.login_modalTarget.classList.add('hidden')
    this.login_formTarget.classList.remove('hidden')
    this.forgot_password_formTarget.classList.add('hidden')
    this.modal_titleTarget.innerText = 'Connexion'
    this.forgot_password_linkTarget.classList.remove('hidden')
  }

  open_unsubscribe_modal() {
    this.unsubscribe_modalTarget.classList.remove('hidden')
  }

  close_unsubscribe_modal() {
    this.unsubscribe_modalTarget.classList.add('hidden')
  }

  login(e) {
    e.preventDefault()
    this.login_errorTarget.classList.add('hidden')
    axios.post('/users/login', {
      user: {
        email: this.email_inputTarget.value,
        password: this.password_inputTarget.value
      }
    }).then(response => {
      if(response.data.success) {
        window.location.replace(response.data.redirect_url)
      } else {
        this.login_errorTarget.classList.remove('hidden')
      }
    })
  }

  unsubscribe(e) {
    e.preventDefault()

    axios.post('/stop-plan', {
      user: {
        email: this.email_input_unsubsribeTarget.value
      }
    }).then(response => {
      console.log(response.data)
      if(response.data.success) {
        this.display_unsub_success()
      } else {
        this.display_unsub_error()
      }
    })
  }

  display_unsub_error() {    
    this.unsub_okTarget.classList.add('hidden')
    this.unsub_errorTarget.classList.remove('hidden')
  }

  display_unsub_success() {
    this.unsub_okTarget.classList.remove('hidden')
    this.unsub_errorTarget.classList.add('hidden')
  }

  forgot_password(e) {
    this.login_formTarget.classList.add('hidden')
    this.forgot_password_formTarget.classList.remove('hidden')
    this.modal_titleTarget.innerText = 'Mot de passe oublié'
    e.target.classList.add('hidden')
  }

  send_reset_password_instructions(e) {
    e.preventDefault()
    this.reset_successTarget.classList.remove('hidden')
  }
}