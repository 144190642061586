// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))


document.addEventListener("turbo:load", function() {
  const chatBtns = document.querySelectorAll(".chat-button")
  if(chatBtns.length === 0) 
    return

  chatBtns.forEach((chatBtn) => {
    chatBtn.addEventListener("click", function() {
      window.tidioChatApi.show()
      window.tidioChatApi.open()
    })
  })
})

