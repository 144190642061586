
import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [ 
    "form_home", 
    'modal_background', 
    'modal_container', 
    'email_input', 
    'email_container', 
    'stripePublicKey',
    'email_input_payment',
    'spinner',
    'lock',
    'name'
  ]

  connect() {

    this.stripe = Stripe(this.stripePublicKeyTarget.value)
    console.log(this.stripePublicKeyTarget.value)
    const elements = this.stripe.elements({
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Mukta:400,600,700&display=swap',
      }],
    })

    const elementsStyles = {
      base: {
        fontSize: '16px',
        fontWeight: '600',
        fontSmoothing: 'antialiased',
        color: '#696871',
        '::placeholder': {
          color: '#9c9aaa'
        }
      },
      invalid: {
        color: "#ff5252",
        iconColor: "#ff5252",
      },
    }

    /* MORE STRIPE ELEMENTS ACTIONS */
    this.cardNumber = elements.create("cardNumber", {
      style: elementsStyles
    })
    this.cardNumber.mount("#card-number")

    this.expiration = elements.create("cardExpiry", {
      style: elementsStyles
    })
    this.expiration.mount("#card-expiry")

    this.cvcNumber = elements.create("cardCvc", {
      style: elementsStyles
    })
    this.cvcNumber.mount("#card-cvc")

    /* Set up event listeners on card */
    this.cardNumber.addEventListener('change', ({
      error
    }) => {
      if(error && error.message) {
        this.displayError(error.message)
        return
      }

      this.displayError()
    })
    this.expiration.addEventListener('change', ({
      error
    }) => {
      if(error && error.message) {
        this.displayError(error.message)
        return
      }

      this.displayError()
    })
    this.cvcNumber.addEventListener('change', ({
      error
    }) => {
      if(error && error.message) {
        this.displayError(error.message)
        return
      }

      this.displayError()
    })
  }

  displayError(error) {
    const errorContainer = document.getElementById('error')
    if (error) {
      errorContainer.classList.remove('hidden');
      errorContainer.innerText = error;
    } else {
      errorContainer.classList.add('hidden');
      errorContainer.innerText = '';
    }
  }

  first_step(event) {
    event.preventDefault()
    this.modal_backgroundTarget.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
    this.email = this.email_inputTarget.value
    if(this.email == '') {
      this.email = this.email_inputTargets[1].value
    }
    this.email_inputTarget.value = ''
    console.log(this.email)
    this.email_containerTarget.innerHTML = this.email
    this.email_input_paymentTarget.value = this.email
  }

  close_modal() {
    console.log('close modal')
    this.modal_backgroundTarget.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }
  loadingActive() {
    this.lockTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
  }

  loadingDisactive() {
    this.lockTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('hidden')
  }

  pay(event) {
    this.loadingActive()
    
    // Setup authenticity token
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    event.preventDefault()

    if(!this.nameTarget.value) {
      this.displayError('Please enter your full name')
      this.loadingDisactive()
      return
    }
    if(!this.email_input_paymentTarget.value) {
      this.displayError('Please enter your email')
      this.loadingDisactive()
      return
    }


    /* Create your 'card' payment method */
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      /* Reference: https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details */
      billing_details: {
        name: this.nameTarget.value,
        email: this.email_input_paymentTarget.value
      },
    }).then((result) => {

      if (result.error) {
        this.displayError(result.error);
        this.loadingDisactive()
        console.error('Error creating Stripe Payment Method: ', result.error)
      } 
      // else if (result.paymentMethod.card.funding === 'prepaid') {
      //   console.log('prepaid')
      //   this.displayError("Questo tipo di carta di pagamento non è accettato dai nostri servizi");
      // }
      else {
        /* Proceed to next step of creating customer and subscription */
        this.stripePaymentMethodHandler(result.paymentMethod)
      }
    })
  }

  stripePaymentMethodHandler(paymentMethod) {
    axios.post('/pay', {
      user: {
        email: this.email_input_paymentTarget.value,
        name: this.nameTarget.value
      },
      stripe_plan: {
        payment_method_id: paymentMethod.id
      }
    }).then(response => {
      if (response.data.error) {
        this.displayError(response.data.error)
        this.loadingDisactive()
        axios.post('/stop-plan?destroy=true', {
          user: {
            email: this.email
          }
        })
      } else {
        this.customerId = response.data.customer_id
        this.stripe.confirmCardPayment(response.data.intent.client_secret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.nameTarget.value,
              email: this.email_input_paymentTarget.value
            },
          },
        }).then((result) => {
          if (result.error) {
            this.displayError(result.error.message)
            console.warn(result.error)
            this.loadingDisactive()
            axios.post('/stop-plan?destroy=true', {
              user: {
                email: this.email_input_paymentTarget.value
              }
            })
          } else {
            axios.post(`/sign-in-user?customer_id=${this.customerId}`, {
              order: {
                profile: this.profile,
              }
            }).then(response => {
              window.location.replace(response.data.redirect_url)
            })
          }
        })
      }
    })
  }
}