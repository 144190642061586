import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'step_container',
    'program_step',
    'error',
    'new_program_modal',
    'suggestion',
    'result',
    'program_id'
  ]

  connect() {
    this.step = 1
  }

  new() {
    document.body.classList.add('overflow-y-hidden')
    this.new_program_modalTarget.classList.remove('hidden')
  }

  next_step(e) {
    console.log('next step')
    e.preventDefault()
    this.errorTarget.classList.add('hidden')
    this.step += 1
    this.program_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  previous_step(e) {
    e.preventDefault()
    this.step -= 1
    this.program_stepTarget.innerText = this.step
    this.step_containerTargets.forEach((step_container) => {
      if(step_container.dataset.step == this.step) {
        step_container.classList.remove('hidden')
      } else {
        step_container.classList.add('hidden')
      }
    })
  }

  submit(e) {
    e.preventDefault()
    axios.post(`/programs/${this.new_program_modalTarget.dataset.programId}/program_sessions`, {
      program_session: {
        suggestion: this.suggestionTarget.value,
        result: this.resultTarget.value
      }
    }).then(response => {
      if(response.data.success) {
        window.location.reload()
      }
    })
  }

  close_modal() {
    document.body.classList.remove('overflow-y-hidden')
    this.new_program_modalTarget.classList.add('hidden')
  }
}
