import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.items = document.querySelectorAll('.accordion-item')
  }

  toggle(event) {
    const accordion_item = event.currentTarget.closest('.accordion-item')
    const accordion_content = accordion_item.querySelector('.accordion-content')
    const accordion_closed_icon = accordion_item.querySelector('.accordion-icon')
    
    !accordion_content.style.maxHeight ? accordion_content.style.maxHeight = '0px' : null
    if (accordion_content.style.maxHeight != '0px') {
      accordion_content.style.maxHeight = '0px'
    } else {
      accordion_content.style.maxHeight = '800px'
    }
    accordion_closed_icon.classList.toggle('active')
  }
}
